import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, m } from 'framer-motion';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Badge, IconButton, List, ListItem } from '@mui/material';
// utils
import getFileData from '../../utils/getFileData';
//
import Image from '../image/Image';
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 16,
    bottom: 16,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

// ----------------------------------------------------------------------

OrderedMultiFilePreview.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
};

export default function OrderedMultiFilePreview({ files, onRemove }) {
  const hasFile = files.length > 0;

  const [orderedFiles, setOrderedFiles] = useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setOrderedFiles(arrayMoveImmutable(orderedFiles, oldIndex, newIndex));
  };

  useEffect(() => {
    setOrderedFiles([...files]);
  }, [files]);

  const SortablePreview = SortableElement(({ file, preview, key }) => (
    <ListItem
      key={key}
      component={m.div}
      sx={{
        p: 0,
        m: 0.5,
        width: 80,
        height: 80,
        borderRadius: 1.25,
        overflow: 'hidden',
        position: 'relative',
        display: 'inline-flex',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Image alt="preview" src={preview} ratio={'1/1'} />

      {onRemove && (
        <IconButton
          size="small"
          onClick={() => onRemove(file)}
          sx={{
            top: 6,
            p: '2px',
            right: 6,
            position: 'absolute',
            color: 'common.white',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon={'eva:close-fill'} />
        </IconButton>
      )}
    </ListItem>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div>
      {items.map((file, index) => {
        const { key, preview } = getFileData(file, index);
        return (
          <StyledBadge
            key={key}
            showZero
            badgeContent={index}
            color="other"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <SortablePreview key={index} file={file} index={index} preview={preview} />
          </StyledBadge>
        );
      })}
    </div>
  ));

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      <AnimatePresence>
        <SortableList pressDelay={80} items={orderedFiles} onSortEnd={onSortEnd} axis={'xy'} />
      </AnimatePresence>
    </List>
  );
}
