// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  annotate: getIcon('ic_annotate'),
  crop: getIcon('ic_annotate'),
  restaurant: getIcon('ic_restaurant'),
  token: getIcon('ic_authenticator'),
  merge: getIcon('ic_animate'),
  carousel: getIcon('ic_carousel'),
  upload: getIcon('ic_upload'),
  doc: getIcon('ic_doc'),
  health: getIcon('ic_health'),
};

const navConfig = [
  // DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: 'selfcheckout_subheader',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard },
      {
        title: 'restaurant_products',
        path: PATH_DASHBOARD.restaurantProduct.root,
        icon: ICONS.cart,
        roles: ['restaurant-products:read'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.restaurantProduct.list },
          { title: 'card', path: PATH_DASHBOARD.restaurantProduct.card },
          { title: 'create', path: PATH_DASHBOARD.restaurantProduct.new },
        ],
      },
      {
        title: 'menus',
        path: PATH_DASHBOARD.menus.root,
        icon: ICONS.calendar,
        roles: ['menu:read'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.menus.list },
          { title: 'card', path: PATH_DASHBOARD.menus.card },
        ],
      },
      {
        title: 'reports',
        path: '#1',
        icon: ICONS.analytics,
        roles: ['report:read'],
        info: <Label color="error">+32</Label>,
      },
      {
        title: 'visualization',
        path: PATH_DASHBOARD.visualization.root,
        icon: ICONS.carousel,
        roles: ['annotation:read'],
        children: [
          {
            title: 'enrollment',
            path: PATH_DASHBOARD.visualization.enrollment,
          },
          { title: 'analysis', path: PATH_DASHBOARD.visualization.analysis },
        ],
      },
      {
        title: 'reporting',
        path: PATH_DASHBOARD.reporting.root,
        icon: ICONS.analytics,
        roles: ['reporting:read'],
        children: [{ title: 'daily', path: PATH_DASHBOARD.reporting.daily }],
      },
    ],
  },

  // HEALTH
  // ----------------------------------------------------------------------
  {
    subheader: 'health_subheader',
    items: [
      {
        title: 'visualization',
        path: PATH_DASHBOARD.health_visualization.root,
        icon: ICONS.carousel,
        roles: ['annotation:read'],
        children: [{ title: 'health', path: PATH_DASHBOARD.health_visualization.analysis }],
      },
      {
        title: 'annotations',
        path: PATH_DASHBOARD.health_annotations.root,
        icon: ICONS.annotate,
        roles: ['annotation:write'],
        children: [{ title: 'images', path: PATH_DASHBOARD.health_annotations.images }],
      },
      {
        title: 'clients',
        path: PATH_DASHBOARD.clients.root,
        icon: ICONS.analytics,
        roles: ['annotation:read'],
        children: [{ title: 'list', path: PATH_DASHBOARD.clients.list }],
      },
    ],
  },

  // TOOLS
  // ----------------------------------------------------------------------
  {
    subheader: 'tools_subheader',
    items: [
      {
        title: 'annotations',
        path: PATH_DASHBOARD.annotations.root,
        icon: ICONS.annotate,
        roles: ['annotation:write'],
        children: [
          { title: 'images', path: PATH_DASHBOARD.annotations.images },
          { title: 'check', path: PATH_DASHBOARD.annotations.check },
          { title: 'crop', path: PATH_DASHBOARD.annotations.crop },
        ],
      },
      {
        title: 'product_tools',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.cart,
        roles: ['products:write'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.product.list },
          { title: 'create', path: PATH_DASHBOARD.product.new },
        ],
      },
      {
        title: 'api_sandbox',
        path: PATH_DASHBOARD.apiSandbox,
        icon: ICONS.upload,
        roles: ['analysis:write'],
      },
      {
        title: 'models',
        path: PATH_DASHBOARD.models.root,
        icon: ICONS.menuItem,
        roles: ['admin:read'],
        children: [
          {
            title: 'feature-clf',
            path: PATH_DASHBOARD.models.featureClassifier,
          },
        ],
      },
      {
        title: 'api_documentation',
        path: PATH_DASHBOARD.apiDocumentation,
        icon: ICONS.doc,
        roles: ['documentation:read'],
      },
    ],
  },

  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'admin_subheader',
    roles: ['admin:read', 'user:write'],
    items: [
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: ['user:write'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
        ],
      },
      {
        title: 'restaurant',
        path: PATH_DASHBOARD.restaurant.root,
        icon: ICONS.restaurant,
        roles: ['restaurant:write'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.restaurant.list },
          { title: 'create', path: PATH_DASHBOARD.restaurant.new },
        ],
      },
      {
        title: 'itentification_token',
        path: PATH_DASHBOARD.identificationToken,
        icon: ICONS.token,
        roles: ['user:write', 'restaurant:write'],
      },
    ],
  },
];

export default navConfig;
