import { createContext, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// hooks
import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const RestaurantContext = createContext();

const fetchRestaurants = async () => {
  const response = await axios.get('/restaurants');
  return response.data;
};

function RestaurantProvider({ children }) {
  const [restaurant, setRestaurant] = useState();
  const { isAuthenticated } = useAuth();

  const { data: restaurantList = [], isLoading } = useQuery({
    queryKey: ['restaurants'],
    queryFn: fetchRestaurants,
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep data in cache for 30 minutes
  });

  const onChangeRestaurant = (event) => {
    if (event?.target) {
      setRestaurant(event.target.value);
    }
  };

  const updateRestaurant = (id) => {
    setRestaurant(id);
  };

  const checkAndSetRestaurant = useCallback(
    (previousRestaurant) => {
      if (restaurantList.length > 0) {
        if (previousRestaurant === undefined) {
          setRestaurant(restaurantList[0].id);
        } else {
          const foundRestaurant = restaurantList.find((r) => Number(r.id) === Number(previousRestaurant));
          setRestaurant(foundRestaurant ? foundRestaurant.id : restaurantList[0].id);
        }
      }
    },
    [restaurantList]
  );

  useEffect(() => {
    if (restaurantList.length > 0) {
      const previousRestaurant = localStorage.getItem('restaurant_id');
      checkAndSetRestaurant(previousRestaurant ?? undefined);
    }
  }, [checkAndSetRestaurant, restaurantList]);

  useEffect(() => {
    if (restaurant !== undefined) {
      localStorage.setItem('restaurant_id', restaurant);
    }
  }, [restaurant]);

  return (
    <RestaurantContext.Provider
      value={{
        restaurant,
        restaurantList,
        setRestaurant,
        isLoading,
        updateRestaurant,
        onChangeRestaurant,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
}

export { RestaurantProvider, RestaurantContext };
