import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import React from 'react';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';
// routes

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
  children: PropTypes.node.isRequired,
  condition: PropTypes.oneOf(['and', 'or']),
};

export default function RoleBasedGuard({ hasContent, roles, children, condition = 'and' }) {
  const { user } = useAuth();
  const userPermissions = user?.permissions;

  /**
   * Check if all roles are present in the array userPermissions
   * @param {Array} rolesToCheck - Array of roles to check against userPermissions
   * @return {Boolean} - True if all roles are present in the array userPermissions
   */
  const hasRoles = (rolesToCheck) => {
    // Check if roles is defined
    if (!rolesToCheck) {
      return true;
    }
    if (rolesToCheck.length === 0) {
      return false;
    }
    if (condition === 'or') {
      return rolesToCheck.some((role) => userPermissions.includes(role));
    }
    return rolesToCheck.every((role) => userPermissions.includes(role));
  };

  if (!hasRoles(roles)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
